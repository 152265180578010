import React, { useEffect } from 'react';
import './StudentLife.scss'
import { Parallax } from 'react-parallax';
import Image from '../../assets/banner.png';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import CustomButton from '../../Components/CustomButton/CustomButton';
import ImageLoader from 'react-imageloader';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getImageUrl } from '../../utils/ImageUrl';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import { fetchStudentLife } from '../../redux/actions/StudentLife';


const StudentLife = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const Student = useSelector(state => state.StudentLife)
  const data = Student.students;
  useEffect(() => {
    dispatch(fetchStudentLife(''))

  }, [0])
  return (
    <React.Fragment>
      {!Student.fetching && data &&
        <div className="about-us-page m-n5">
          <Parallax
            bgImage={data.alternateImage ? getImageUrl(data.alternateImage.url) : 'http://placehold.jp/1900x400.png'}
            blur={1}
            strength={500}
          >
            <div className="life-overlay" style={{ minHeight: '300px' }}>
              <Container className="py-5">
                <Row className="py-5">
                  <Col lg={7}>
      <h1 className="text-bold text-uppercase" style={{ color: 'white' }}>{data.title || 'Students Life at KCCS'}</h1>
                    <div className="paragraph" style={{ color: '#cccccc', fontSize: "20px" }} dangerouslySetInnerHTML={{ __html: data.shortDescription || ''}}>
                      
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Parallax>
          <Container className="pb-5">
            <Row className="pb-5 justify-content-xl-between mt-5">
              <Col lg={7}>
                <div dangerouslySetInnerHTML={{ __html: data.content || 'N/A'}}></div>
              </Col>
              <Col lg={5}>
                
                <ImageLoader className="image-100" src={data.featuredImage ? getImageUrl(data.featuredImage.url) : 'https://dummyimage.com/400x900/ccc/fff&text=KCCS+Image'} />
              </Col>
            </Row>
          </Container>
        </div>
      }

      <div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center py-5">
        {Student.fetching &&
          <Loading className="align-items-center" />
        }
        {!Student.fetching && !data &&
          <EmptyHolder text="Student story available" />
        }
      </div>
    </React.Fragment>
  );
}

export default StudentLife;