import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Banner from '../../assets/banner.png';
import './Slider.scss';
import { Container } from 'react-bootstrap';
import CustomButton from '../CustomButton/CustomButton';
import Pagination from '../Pagination/Pagination';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { getImageUrl } from '../../utils/ImageUrl';
import EmptyHolder from '../EmptyHolder/EmptyHolder';
import Pageloading from '../Pageloading/Pageloading';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Slider = (props) => {
	const { fetching, data } = props;
	const [ slideIndex, setSlideIndex ] = useState(0);
	const history = useHistory();
	const gotoAdmission = (url) => {
		history.push('/admission');
		// window.open(url, '_blank')
	};
	const styles = {
		slide: {
			padding: 0,
			maxHeight: '100vh',
			color: '#fff',
			background: 'black',
			minHeight: '98vh',
			background: '#0071bc'
		},
		slide1: {
			backgroundColor: '#FEA900'
		},
		slide2: {
			backgroundColor: '#B3DC4A'
		},
		slide3: {
			backgroundColor: '#6AC0FF'
		}
	};
	return (
		<div style={{ ...styles.slide }}>
			{fetching &&
			data.length === 0 && (
				<div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center">
					{fetching && <Loading className="align-items-center" />}
					{data.length === 0 && <EmptyHolder text="Banners not available" />}
				</div>
			)}
			{fetching && <Pageloading />}

			{data &&
			data.length > 0 && (
				<React.Fragment>
					<AutoPlaySwipeableViews
						index={slideIndex}
						onChangeIndex={(index) => setSlideIndex(index)}
						className="home-main-slider"
						enableMouseEvents
						autoplay={true}
						direction="incremental"
						interval={6000}
						springConfig={{ duration: '0.7s', easeFunction: 'ease-in-out', delay: '0s' }}
					>
						{data &&
							data.map((item, index) => {
								return (
									<div
										key={index}
										className="banner-item"
										style={Object.assign({}, styles.slide, styles.slide1)}
									>
										<div className="content" />
										<div className="overlay d-flex align-items-center">
											<Container>
												<div className="content">
													<React.Fragment>
														<h1 className="text-uppercase slider-text">{item.title}</h1>

														{item.url && (
															<CustomButton
																onClick={() => {
																	gotoAdmission(item.url);
																}}
																// className="slide-in-bck-center"
																text={item.buttonTitle}
																variant="secondary"
																type="bordered"
															/>
														)}
													</React.Fragment>
												</div>
											</Container>
										</div>
										<img
											className="kenburns-top"
											width="100%"
											src={getImageUrl(item.imageUrl.url)}
											alt="Banner"
										/>
									</div>
								);
							})}
					</AutoPlaySwipeableViews>
					<div className="banner-indicator">
						{data.length > 1 && (
							<Pagination
								dots={data.length}
								current={slideIndex}
								onChangeIndex={(e) => {
									setSlideIndex(e);
								}}
							/>
						)}
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default Slider;
