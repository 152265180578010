import React, { useRef, useEffect, useState } from 'react';
import { Nav, Container, NavDropdown } from 'react-bootstrap';
import './NavigationBar.scss';
import { history } from '../../redux/store';
import Logo from '../../assets/logo.png';
import { useSelector } from 'react-redux';
import NavMenus from './NavMenus';

const NavigationBar = (props) => {
	const { menu } = props;
	const navRef = useRef();
	const [ solidNav, setSolidNav ] = useState(false);
	const route = useSelector((state) => state.router);

	const onscrollAdjust = () => {
		let scrollPosition = window.scrollY;
		if (scrollPosition > 50) {
			setSolidNav(true);
		} else {
			setSolidNav(false);
		}
	};
	useEffect(
		() => {
			if (route.location.pathname === '/') {
				setSolidNav(false);
				window.addEventListener('scroll', onscrollAdjust);
				props.onChange(0);
			} else {
				setSolidNav(true);
				props.onChange(navRef.current.clientHeight);
				return window.removeEventListener('scroll', onscrollAdjust);
			}
		},
		[ props, route ]
	);
	return (
		<Nav
			ref={navRef}
			className={`navbar-dark bg-transparent custom-navigation py-3 position-fixed align-items-center ${solidNav
				? 'solid-nav pt-3'
				: 'pt-5'}`}
		>
			<Container className="d-flex justify-content-around">
				<span
					onClick={() => {
						history.push('/');
					}}
				>
					<img
						height="60px"
						src="https://admin.kccs.edu.np/api/uploads/logo_f067a597_8be79e3b6d.png"
						alt="KCCS College"
					/>
				</span>
				<div className="navigation-right ml-auto align-items-center d-flex">
					<NavMenus />
				</div>
			</Container>
		</Nav>
	);
};

export default NavigationBar;
