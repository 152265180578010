import { GET_STUDENT_LIFE, GET_STUDENT_LIFE_SUCCESS, GET_STUDENT_LIFE_FAILURE } from "../constants"

const INIT_STATE = {
    students: false,
    fetching: false,
    error: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_STUDENT_LIFE:
            return { ...state, fetching: true, error: false }

        case GET_STUDENT_LIFE_SUCCESS:
            return { ...state, fetching: false, error: false, students: action.payload.student }

        case GET_STUDENT_LIFE_FAILURE:
            return { ...state, fetching: false, error: action.payload.err }

        default:
            return { ...state }
    }
}