import React, { useEffect, useState } from 'react';
import Slider from '../../Components/Slider/Slider';
import EventSection from '../Dashboard/EventSection/EventSection';
import Features from '../Dashboard/Features/Features';
import * as data from '../../data/data';
import CourseSection from '../Dashboard/CourseSection/CourseSection';
import Enrollment from '../Dashboard/Enrollment/Enrollment';
import StudentLife from '../Dashboard/StudentLife/StudentLife';
import OurTeam from '../Dashboard/OurTeam/OurTeam';
import ImgGallery from '../Dashboard/Gallery/Gallery';
import Space from '../../Components/Space/Space';
import { fetchOrganizationDetails } from '../../redux/actions/organizationInfo';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanners } from '../../redux/actions/Banners';
import { fetchEvents } from '../../redux/actions/Events';
import { fetchFacilities } from '../../redux/actions/Facilities';
import { fetchCourses } from '../../redux/actions/Courses';
import { fetchTeams } from '../../redux/actions/Teams';
import { fetchGalleries } from '../../redux/actions/Galleries';
import { fetchStudentLife } from '../../redux/actions/StudentLife';
import PrincipalMessage from '../Dashboard/PrincipalMessage/PrincipalMessage';

const Home = (props) => {
	const banners = useSelector((state) => state.Banners);
	const events = useSelector((state) => state.Events);
	const Organization = useSelector((state) => state.Organization);
	const Facilities = useSelector((state) => state.Facilities);
	const Course = useSelector((state) => state.Course);
	const Teams = useSelector((state) => state.Teams);
	const Galleries = useSelector((state) => state.Galleries);
	const StudentLifes = useSelector((state) => state.StudentLife);
	const dispatch = useDispatch();
	const [ images, setImages ] = useState([]);

	useEffect(
		() => {
			dispatch(fetchOrganizationDetails());
			dispatch(fetchBanners('status=true'));
			dispatch(fetchEvents('Status=true&_sort=DateFrom:asc&_limit=3'));
			dispatch(fetchFacilities('status=true&_limit=6'));
			dispatch(fetchCourses('status=true&_sort=order:asc'));
			dispatch(fetchTeams('status=true&_limit=6&_sort=order:asc'));
			dispatch(fetchGalleries('status=true&_limit=6'));
			dispatch(fetchStudentLife(''));
		},
		[ 0 ]
	);

	useEffect(
		() => {
			let tempGal = [];
			Galleries.galleries &&
				Galleries.galleries.map((gal, index) => {
					tempGal = [ ...tempGal, ...gal.images ];
				});
			setImages(tempGal);
		},
		[ Galleries ]
	);
	return (
		<React.Fragment>
			<div style={{ position: 'relative' }}>
				<Slider fetching={banners.fetching} data={banners.banners} />
			</div>
			<EventSection fetching={events.fetching} data={events.events} />
			{/* <Space size={60} /> */}

			{/* <Features fetching={Facilities.fetching} data={Facilities.facilities} />
			<Space size={100} /> */}

			{/* <Enrollment />
            <Space size={100} /> */}
			<PrincipalMessage data={Organization} fetching={Organization.fetching} />
			<Space size={100} />

			<CourseSection fetching={Course.fetching} data={Course.courses} />
			<Space size={100} />

			<StudentLife data={StudentLifes.students} fetching={StudentLifes.fetching} />
			<Space size={100} />

			<OurTeam data={Teams.teams} fetching={Teams.fetching} />

			<ImgGallery data={images} fetching={Galleries.fetching} />
		</React.Fragment>
	);
};

export default Home;
