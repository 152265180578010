import React from 'react';
import courseImage from '../../assets/mba.jpg';
import './CourseCard.scss';
import { getImageUrl } from '../../utils/ImageUrl';
const CourseCard = (props) => {
	const { onClick, data } = props;
	return (
		<div className="course-card-wrapper" onClick={onClick}>
			<div className="course-container">
				<div className="course-image" style={{minHeight: 100}}>
					<img
						src={data.imageUrl ? getImageUrl(data.imageUrl.url) : 'http://placehold.jp/400x150.png'}
						alt="Course Item"
					/>
				</div>
				<div className="course-details d-flex align-items-center flex-column">
					<div className="short-name" style={{color: data?.color, borderColor: data?.color}}>{data.shortName || 'N/A'}</div>
					<h5 style={{color: data?.color}}>{data.title}</h5>
					{/* <p>{data.duration || 'N/A'}</p> */}
				</div>
			</div>
		</div>
	);
};

export default CourseCard;
