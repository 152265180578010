

export const GET_ORGANIZATION_DETAILS = 'GET_ORGANIZATION_DETAILS';
export const GET_ORGANIZATION_DETAILS_SUCCESS = 'GET_ORGANIZATION_DETAILS_SUCCESS';
export const GET_ORGANIZATION_DETAILS_FAILURE = 'GET_ORGANIZATION_DETAILS_FAILURE';

export const GET_STUDENT_LIFE = "GET_STUDENT_LIFE";
export const GET_STUDENT_LIFE_SUCCESS = "GET_STUDENT_LIFE_SUCCESS";
export const GET_STUDENT_LIFE_FAILURE = "GET_STUDENT_LIFE_FAILURE";


export const UPDATE_ADMISSION_DATA = 'UPDATE_ADMISSION_DATA';


export const GET_BANNERS = "GET_BANNERS";
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";
export const GET_BANNERS_FAILURE = "GET_BANNERS_FAILURE";

export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";


export const GET_FACILITIES = "GET_FACILITIES";
export const GET_FACILITIES_SUCCESS = "GET_FACILITIES_SUCCESS";
export const GET_FACILITIES_FAILURE = "GET_FACILITIES_FAILURE";

export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAILURE = "GET_COURSES_FAILURE";

export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAILURE = "GET_TEAMS_FAILURE";

export const GET_GALLERIES = "GET_GALLERIES";
export const GET_GALLERIES_SUCCESS = "GET_GALLERIES_SUCCESS";
export const GET_GALLERIES_FAILURE = "GET_GALLERIES_FAILURE";


export const CREATE_ADMISION_REQUEST = "CREATE_ADMISION_REQUEST";
export const CREATE_ADMISION_SUCCESS = "CREATE_ADMISION_SUCCESS";
export const CREATE_ADMISION_FAILURE = "CREATE_ADMISSION_FAILURE";


//course details
export const GET_COURSE_DETAILS = "GET_COURSE_DETAILS";
export const GET_COURSE_DETAILS_SUCCESS = "GET_COURSE_DETAILS_SUCCESS";
export const GET_COURSE_DETAILS_FAILURE = "GET_COURSE_DETAILS_FAILURE"