import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { baseUrl } from '../../api/api';
import './Notice.scss';
import { getImageUrl } from '../../utils/ImageUrl';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';


export const Notice = (props) => {
    const [notice, setNotice] = useState(false)

    useEffect(() => {
        Axios({
            method: 'GET',
            url: `${baseUrl}/notices`,
        }).then(data => {
            setNotice(data.data && data.data || [])
        })
            .catch(err => console.log(err))
    }, [0])
    return (
        <Container>
            <SectionTitle text="Notices"/>
            <div className="notices-wrapper">
                {notice && notice.map((n, i) => {
                    return (
                        <Row className="notice-item d-flex align-items-center py-5">
                            <Col lg={4}>
                                <img style={{ width: '100%' }} src={n.imageUrl && getImageUrl(n.imageUrl.url)} />
                            </Col>
                            <Col lg={8}>
                            <div className="ml-4 flex-fill">
                                <h5 className="text-bold">{n.title}</h5>
                                <div dangerouslySetInnerHTML={{ __html: n.content && n.content}}></div>
                            </div>
                            </Col>
                            <hr/>
                            
                        </Row>
                    )
                })}
            </div>
        </Container>
    )
}