import React, { useContext, useState } from 'react';
import LogoDark from '../../assets/logo500Dark.png';
import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/closeAlt.svg';
import { ThemeContext } from '../../utils/ThemeContext';
import { Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { history } from '../../redux/store';
import NavMenus from './NavMenus';
const MobileNavigation = (props) => {
	const { menu } = props;
	const theme = useContext(ThemeContext);
	const [ activeBar, setActiveBar ] = useState(false);
	const [ activeItem, setActiveItem ] = useState('');
	const onClickNavButton = () => {
		history.push('/contact-us');
	};
	const navigateMenu = (data) => {
		if (data.child) {
			setActiveItem(data.label);
		} else {
			if (data.redirect) {
				window.open(data.path, '_self');
			} else {
				history.push(data.path);
				setActiveBar(false);
			}
		}
	};
	return (
		<div className={`mobile-navigation ${activeBar ? 'active' : ''}`}>
			<div className="top-bar-stripe d-flex align-items-center">
				<img
					src="https://admin.kccs.edu.np/api/uploads/logo500_Dark_6df78159_d5c30d860c.png"
					style={{ height: '70%', width: 'auto' }}
					onClick={() => {
						history.push('/');
						setActiveBar(false);
					}}
				/>
				{activeBar ? (
					<CloseIcon
						width="25px"
						fill={theme.primaryColor}
						onClick={() => {
							setActiveBar(false);
						}}
					/>
				) : (
					<MenuIcon
						className="menu-shortcut"
						width="25px"
						fill={theme.primaryColor}
						onClick={() => {
							setActiveBar(true);
						}}
					/>
				)}
			</div>
			<div className="bottom-details">
				<NavMenus className="d-flex flex-column px-5 pt-4" />
			</div>
		</div>
	);
};

export default MobileNavigation;
