import React from 'react';
import { Nav, Container } from 'react-bootstrap';
import './NavigationBar.scss';
import { history } from '../../redux/store';
import Logo from '../../assets/logo.png';
import NavMenus from './NavMenus';

const NavigationBarAlt = (props) => {
	const { menu } = props;
	props.onChange(150);

	return (
		<Nav
			className={`navbar-dark bg-transparent custom-navigation py-3 position-fixed align-items-center solid-nav pt-3`}
		>
			<Container className="d-flex justify-content-around">
				<div
					onClick={() => {
						history.push('/');
					}}
				>
					<img
						height="60px"
						src="https://admin.kccs.edu.np/api/uploads/logo_f067a597_8be79e3b6d.png"
						alt="KCCS College"
					/>
				</div>
				<div className="navigation-right ml-auto align-items-center d-flex">
					<NavMenus />
				</div>
			</Container>
		</Nav>
	);
};

export default NavigationBarAlt;
