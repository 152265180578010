import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LogoImage from '../../assets/logo500.png';
import { ReactComponent as LocationIcon } from '../../assets/location.svg';
import { ReactComponent as PhoneIcon } from '../../assets/phone.svg';
import { ReactComponent as MainIcon } from '../../assets/mail.svg';
import { ReactComponent as ClockIcon } from '../../assets/clock.svg';
import './Footer.scss';
import { history } from '../../redux/store';
import { useSelector } from 'react-redux';

const Footer = () => {
	const About = useSelector((state) => state.Organization);
	const organization = About.info;
	const Course = useSelector((state) => state.Course);

	return (
		<div className="footer-wrapper">
			<div className="footer-top py-5">
				<Container>
					<Row className="flex-column align-items-center">
						<img
							src={'https://admin.kccs.edu.np/api/uploads/logo500_ee801586_49dd2ac8a6.png'}
							alt="KCCS"
							height="100px"
						/>
						<h4 className="text-uppercase text-center pt-5">
							We are available for almost every Solutions in higher Education.
						</h4>
					</Row>
				</Container>
			</div>
			<div className="footer-bottom py-5">
				<Container>
					<Row className="align-items-start">
						<Col lg={2} className="d-flex flex-column align-items-start ">
							<h4>Courses</h4>

							<Row>
								{Course.courses &&
									Course.courses.map((course, index) => {
										return (
											<Col lg={6} className="py-3">
												<span
													onClick={() => {
														history.push(`/courses/${course.slug}`);
													}}
												>
													{course.title}
												</span>
											</Col>
										);
									})}
							</Row>
						</Col>
						<Col lg={4} className="d-flex offset-2 flex-column align-items-start">
							<h4>Admission and Fees</h4>
							<h4 className="pt-4">Useful Links</h4>
							<Row>
								<Col lg={6} className="d-flex flex-column align-items-start">
									{/* <span
                                        onClick={() => {
                                            history.push('/')
                                        }}
                                    >Home</span>
                                    <span onClick={() => {history.push('/about-us')}}>About us</span>
                                    <span onClick={() => {history.push('/events')}}>Events</span> */}

									<span
										onClick={() => {
											window.open('http://www.tribhuvan-university.edu.np/', '_blank');
										}}
									>
										Tribhuvan University
									</span>
									<span
										onClick={() => {
											window.open('https://www.neb.gov.np/', '_blank');
										}}
									>
										National Examination Board
									</span>
									<span
										onClick={() => {
											window.open('https://moe.gov.np/', '_blank');
										}}
									>
										Ministry of Education, Science and Technology
									</span>
								</Col>
								<Col lg={6} className="d-flex flex-column align-items-start">
									<span>Privacy Policy</span>
									<span>Terms & Conditions</span>
									{/* <span>Help</span> */}
								</Col>
							</Row>
						</Col>
						<Col lg={4} className="contact">
							<h4>Contact</h4>

							<p className="item d-flex">
								<LocationIcon fill="white" width="25px" />
								<span>{organization && organization.address && organization.address}</span>
							</p>
							<p className="item d-flex">
								<PhoneIcon fill="white" width="25px" />
								<span>
									{organization && organization.primaryPhone && organization.primaryPhone}
								</span>{' '}
								<span>/</span>{' '}
								<span>
									{organization && organization.secondaryPhone && organization.secondaryPhone}
								</span>
							</p>
							<p className="item d-flex">
								<MainIcon fill="white" width="25px" />
								<span>{organization && organization.primaryEmail && organization.primaryEmail}</span>
							</p>
							<p className="item d-flex">
								<ClockIcon fill="white" width="25px" />
								<span>
									Sunday - Friday<br />06:00AM - 05:00 PM
								</span>
							</p>
						</Col>
					</Row>
				</Container>
				<Container className="d-flex justify-content-center pt-5">
					<p className="copy-right pt-5">Copyright ©2020 All rights reserved | CSIT Department, CCS </p>
				</Container>
			</div>
		</div>
	);
};

export default Footer;
