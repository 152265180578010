import React, { useState, useEffect } from 'react';
import SectionTitle from '../../../Components/SectionTitle/SectionTitle';
import { Container, Row, Col } from 'react-bootstrap';
import CourseCard from '../../../Components/CourseCard/CourseCard';
import ShowMore from '../../../Components/ShowMore/ShowMore';
import ScrollAnimation from 'react-animate-on-scroll';
import { useHistory } from 'react-router-dom';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
import Slider from 'react-slick';
import './CourseSection.scss';
const CourseSection = (props) => {
	const history = useHistory();
	const [ courseData, setCourseData ] = useState(false);
	const { fetching, data } = props;
	const showMore = () => {
		setCourseData(props.data && props.data);
	};
	useEffect(
		() => {
			setCourseData(props.data && props.data.slice(0, 6));
		},
		[ props ]
	);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1
	};
	return (
		<React.Fragment>
			<SectionTitle text="All Courses" />
			<Container>
				{/* <Row className="m-0 justify-content-between">
					{courseData &&
						courseData.map((cs, index) => {
							return (
								<Col key={index} lg={4} md={4} style={{ padding: '0px 20px' }}>
									<ScrollAnimation animateIn="scale-in-center">
										<CourseCard data={cs} onClick={() => history.push(`/courses/${cs.slug}`)} />
									</ScrollAnimation>
								</Col>
							);
						})}
				</Row>
				{props.data &&
				props.data.length !== courseData.length && <ShowMore variant="primary" onClick={showMore} />} */}

				<div>
					<Slider {...settings} className="class--slider">
						{courseData &&
							courseData.map((cs, index) => {
								return (
									<div>
										<div key={index} style={{ padding: '0px 20px' }}>
											<CourseCard data={cs} onClick={() => history.push(`/courses/${cs.slug}`)} />
										</div>
									</div>
								);
							})}
					</Slider>
				</div>
			</Container>

			<div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center">
				{fetching && <Loading className="align-items-center" />}
				{data.length === 0 && <EmptyHolder text="Banners not available" />}
			</div>
		</React.Fragment>
	);
};

export default CourseSection;
