import React from 'react';
import Event from '../../../Components/Event/Event';
import { Container, Col, Row } from 'react-bootstrap';
import './EventSection.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';

const EventSection = (props) => {
	const { fetching, data } = props;
	return (
		<div className="event--section" style={{ minHeight: '30vh' }}>
			<Container>
				{data.length > 0 && (
					<Row className="m-0 justify-content-start align-items-stretch">
						<Col lg={3} className="p-0">
							{/* <ScrollAnimation animateIn="slide-in-top"> */}
							<Event variant="primary" title={true} fetching={fetching} data={data && data[0]} />
							{/* </ScrollAnimation> */}
						</Col>
						{data.length > 1 &&
							data.slice(1, 3).map((ev, index) => {
								return (
									<Col lg={4} className="px-5">
										<ScrollAnimation
											style={{ height: 'calc(100% - 40px)' }}
											animateOnce
											animateIn="slide-in-right"
										>
											<Event title={index === 0} fetching={fetching} data={ev} />
										</ScrollAnimation>
									</Col>
								);
							})}
					</Row>
				)}

				<div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center py-5">
					{fetching && <Loading className="align-items-center" />}
					{!fetching && data.length === 0 && <EmptyHolder text="Events not found" className="mb-4 pb-4" />}
				</div>
			</Container>
		</div>
	);
};

export default EventSection;
