import React from 'react';
import './EmptyHolder.scss';
import { ReactComponent as EmptyIcon } from '../../assets/error.svg';
import { Button } from 'react-bootstrap';
import { history } from '../../redux/store';

const EmptyPage = (props) => {
	return (
		<div class="d-flex justify-content-center align-items-center" id="main">
			<h1 class="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
			<div class="inline-block align-middle">
				<h2 class="font-weight-normal lead" id="desc">
					The page you requested was not found.
				</h2>
				<Button onClick={() => history.push('/')} className="mt-4">
					Go Home
				</Button>
			</div>
		</div>
	);
};

export default EmptyPage;
