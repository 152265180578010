import React from 'react';
import ReactDOM from 'react-dom';
import App from './Container/App';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './redux/store';
import { Provider } from 'react-redux';
import { ThemeContext, themes } from './utils/ThemeContext';
import "animate.css/animate.min.css";

ReactDOM.render(
  <Provider store={configureStore()}>
    <ThemeContext.Provider value={themes.light}>
      <App />
    </ThemeContext.Provider>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
