import React, { useState, useEffect } from 'react';
import './Gallery.scss';
import Gallery from 'react-grid-gallery';
import ScrollContainer from 'react-indiana-drag-scroll';

import { Row, Col } from 'react-bootstrap';
import { getImageUrl } from '../../../utils/ImageUrl';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
const ImgGallery = (props) => {
	const [ IMAGES, setIMAGES ] = useState([]);
	const { fetching, data } = props;
	useEffect(
		() => {
			let tempImages = [];
			props.data &&
				props.data.length > 0 &&
				props.data.map((img, index) => {
					tempImages = [
						...tempImages,
						{
							src: getImageUrl(img.url),
							thumbnail: getImageUrl(img.formats.thumbnail.url),
							thumbnailWidth: img.formats.thumbnail.width,
							thumbnailHeight: img.formats.thumbnail.height
						}
					];
				});
			setIMAGES(tempImages);
		},
		[ props ]
	);

	return (
		<React.Fragment>
			<Row className="m-0">
				<Col>
					<ScrollContainer>
						<Gallery images={IMAGES} />
					</ScrollContainer>
				</Col>
			</Row>
			{/* <div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center py-5">
                {fetching &&
                    <Loading className="align-items-center" />
                }
                {!fetching && data.length === 0 &&
                    <EmptyHolder text="Banners not available" />
                }
            </div> */}
		</React.Fragment>
	);
};

export default ImgGallery;
