import React from 'react';
import './About.scss';
import { Parallax } from 'react-parallax';
import Image from '../../assets/about-us.jpg';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import CustomButton from '../../Components/CustomButton/CustomButton';
import ImageLoader from 'react-imageloader';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getImageUrl } from '../../utils/ImageUrl';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';

const About = (props) => {
	const history = useHistory();
	const About = useSelector((state) => state.Organization);
	const organization = About.info;

	function preloader() {
		return <Loading />;
	}
	return (
		<React.Fragment>
			{!About.fetching &&
			organization && (
				<div className="about-us-page mt-n5">
					<Parallax bgImage={Image} blur={1} strength={500}>
						<div className="life-overlay" style={{ minHeight: '300px' }}>
							<Container className="py-5">
								<Row className="py-5 align-items-center">
									<Col lg={7}>
										<h1 className="text-bold text-uppercase" style={{ color: 'white' }}>
											About Us
										</h1>
										<div
											className="paragraph text-justify"
											style={{ color: '#cccccc', fontSize: '20px' }}
											dangerouslySetInnerHTML={{
												__html: organization.organizationIntroduction || ''
											}}
										/>
									</Col>
									<Col lg={5}>
										<img
											src="https://admin.kccs.edu.np/api/uploads/IMG_4785_bc943ba5cc.jpeg"
											width="100%"
											style={{ borderRadius: 10 }}
										/>
									</Col>
								</Row>
							</Container>
						</div>
					</Parallax>
					<Container className="pb-5">
						<Row className="pb-5 justify-content-xl-between">
							<Col lg={7}>
								<SectionTitle
									text="Vision"
									className="text-uppercase"
									left
									style={{ justifyContent: 'flex-start' }}
								/>
								<p
									className="paragraph text-justify"
									dangerouslySetInnerHTML={{ __html: organization.vision || 'Coming Soon' }}
								/>
								<SectionTitle
									text="Mission"
									className="text-uppercase"
									left
									style={{ justifyContent: 'flex-start' }}
								/>
								<p
									className="paragraph text-justify"
									dangerouslySetInnerHTML={{ __html: organization.mission || 'Coming soon' }}
								/>
								<SectionTitle
									text="CORE VALUE "
									className="text-uppercase"
									left
									style={{ justifyContent: 'flex-start' }}
								/>
								<p
									className="paragraph text-justify"
									dangerouslySetInnerHTML={{ __html: organization.objectives || 'Coming Soon' }}
								/>
							</Col>
							<Col lg={4} className="py-5 d-flex flex-column align-items-start">
								<CustomButton
									onClick={() => history.push('/team')}
									className="mb-4"
									text="Find more about team"
									type="secondary"
									variant="bordered"
								/>
								<CustomButton
									onClick={() => history.push('/courses')}
									className="mb-4"
									text="Find more Courses"
									type="primary"
									variant="bordered"
								/>
								<CustomButton
									onClick={() => history.push('/events')}
									className="mb-4"
									text="Find more events"
									type="secondary"
									variant="bordered"
								/>

								<ImageLoader
									preloader={preloader}
									imgProps={{ width: '100%' }}
									src={
										organization.featuredImage ? (
											getImageUrl(organization.featuredImage.url)
										) : (
											'https://dummyimage.com/400x900/ccc/fff&text=KCCS+Image'
										)
									}
								/>
							</Col>
						</Row>
					</Container>
				</div>
			)}

			<div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center py-5">
				{About.fetching && <Loading className="align-items-center" />}
				{!About.fetching && !organization && <EmptyHolder text="Information not found" />}
			</div>
		</React.Fragment>
	);
};

export default About;
