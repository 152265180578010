import React from 'react';
import './Pagination.scss';

const Pagination = props => {
    const {dots, current} = props;
    return (
        <div className="pagination-wrapper">
            {Array(dots).fill(null).map((d, index)=>{
                return (
                    <div key={index} className={`pagination-dots ${index === current ? 'active' : '' }`} onClick={()=>{props.onChangeIndex(index)}}></div>
                )
            })
            }
        </div>
    )
}

export default Pagination;