import React, { useEffect } from 'react';
import { Container, Col, Row, Accordion, Button } from 'react-bootstrap';
import './Details.scss';
import CustomButton from '../../../Components/CustomButton/CustomButton';
import { useHistory } from 'react-router-dom';
import { fetchCourseDetails } from '../../../redux/actions/Courses';
import { useDispatch, useSelector } from 'react-redux';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
import Loading from '../../../Components/Loading/Loading';
import { getImageUrl } from '../../../utils/ImageUrl';
import Card from '../../../Components/Card/Card';
import Divider from '../../../Components/Divider/Divider';

const CourseDetails = (props) => {
	const history = useHistory();
	const Course = useSelector((state) => state.Course);
	const courseData = Course.selectedCourse;
	const dispatch = useDispatch();
	useEffect(
		() => {
			dispatch(fetchCourseDetails(`status=true&slug_eq=${props.match.params.slug}`));
			// dispatch(fetchCourses(`status=true&_limit=2&slug_ne=${props.match.params.slug}`))
		},
		[ 0 ]
	);
	return (
		<React.Fragment>
			{!Course.fetching &&
			courseData && (
				<div className="course-details mt-n5">
					<div className="course-details-title pt-5 pb-4">
						<Container>
							<Row className="justify-content-between align-items-center">
								<Col lg={7}>
									<h4 className="text-bold">{courseData.title}</h4>
									<p>{courseData.description || 'N/A'}</p>
								</Col>
								<Col lg={3}>
									<CustomButton
										onClick={() => {
											history.push('/admission');
										}}
										text="Apply Now"
										variant="primary"
										type="solid"
									/>
								</Col>
							</Row>
						</Container>
					</div>
					<Container className="py-5">
						<Row className="justify-content-between full-table">
							<Col lg={7}>
								<Card style={{ marginBottom: 10, padding: 30 }}>
									<h5 className="text-bold">Programme objective</h5>
									<Divider />
									<p
										style={{ color: '#034d80' }}
										dangerouslySetInnerHTML={{ __html: courseData.objectives || 'N/A' }}
									/>
								</Card>
								<Card style={{ marginBottom: 10, padding: 30 }}>
									<h5 className="text-bold  mb-3">Eligibility</h5>
									<Divider />

									<p
										style={{ color: '#034d80' }}
										dangerouslySetInnerHTML={{ __html: courseData.eligibility || 'N/A' }}
									/>
								</Card>
								{courseData?.scope &&  
								<Card style={{ marginBottom: 10, padding: 30 }}>
									<h5 className="text-bold  mb-3">Scope and Career Prospects</h5>
									<Divider />

									<p
										style={{ color: '#034d80' }}
										dangerouslySetInnerHTML={{ __html: courseData.scope || 'N/A' }}
									/>
								</Card>
								}
								{courseData?.internshipPlacement && 
								<Card style={{ marginBottom: 10, padding: 30 }}>
									<h5 className="text-bold  mb-3">Career</h5>
									<Divider />

									<p
										style={{ color: '#034d80' }}
										dangerouslySetInnerHTML={{ __html: courseData.internshipPlacement || 'N/A' }}
									/>
								</Card>
								}
								<Card style={{ marginBottom: 10, padding: 30 }}>
									<h5 className="text-bold  mb-3">Syllabus / Curriculam Structure</h5>
									<Divider />

									<div
										style={{ color: '#034d80' }}
										dangerouslySetInnerHTML={{ __html: courseData.curriculam || 'N/A' }}
									/>
								</Card>
							</Col>
							<Col lg={5}>
								{courseData.featuredImage && courseData.featuredImage?.map((image) => {
									return <img
										width="100%"
										alt="KCC College"
										src={
											courseData.featuredImage ? (
												getImageUrl(image.url)
											) : (
												'https://dummyimage.com/400x900/ccc/fff&text=KCCS+Image'
											)
										}
										style={{borderRadius: 14, marginBottom: 10}}
								/>
								})}
								
							</Col>
						</Row>
						<Row className="justify-content-center my-4">
							<Col>
								<CustomButton
									onClick={() => {
										history.push('/admission');
									}}
									text="Apply Now"
									variant="primary"
									type="solid"
								/>
							</Col>
						</Row>
					</Container>
				</div>
			)}
			<div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center py-5">
				{Course.fetching && <Loading className="align-items-center" />}
				{!Course.fetching && !courseData && <EmptyHolder text="Banners not available" />}
			</div>
		</React.Fragment>
	);
};

export default CourseDetails;
