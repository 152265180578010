import { GET_COURSES, GET_COURSES_SUCCESS, GET_COURSES_FAILURE, GET_COURSE_DETAILS, GET_COURSE_DETAILS_SUCCESS, GET_COURSE_DETAILS_FAILURE } from "../constants"

const INIT_STATE = {
    courses: false,
    fetching: false,
    error: false,
    selectedCourse: false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COURSES:
            return { ...state, fetching: true, error: false }

        case GET_COURSES_SUCCESS:
            return { ...state, fetching: false, error: false, courses: action.payload.courses }

        case GET_COURSES_FAILURE:
            return { ...state, fetching: false, error: action.payload.err }

             //get course Details
        case GET_COURSE_DETAILS:
            return { ...state, fetching: true, error: false }

        case GET_COURSE_DETAILS_SUCCESS:
            return { ...state, fetching: false, error: false, selectedCourse: action.payload.course[0] }

        case GET_COURSE_DETAILS_FAILURE:
            return { ...state, fetching: false, error: action.payload.err }


        default:
            return { ...state }
    }
}