import Axios from "axios";
export const baseUrl = "https://admin.kccs.edu.np/api";

export const takeOrganizationDetails = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/about-organization?status=true`
    })
}

export const takeBanners = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/banners?${data ? data : ''}`
    })
}

export const takeEvents = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/events?${data ? data : ''}`
    })
}

export const takeFacilities = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/features?${data ? data : ''}`
    })
}
export const takeCourses = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/courses?${data ? data : ''}`
    })
}

export const takeCourseDetails = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/courses?${data ? data : ''}`
    })
}

export const takeTeams = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/our-teams?${data ? data : ''}`
    })
}

export const takeGalleries = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/galleries?${data ? data : ''}`
    })
}

export const takeStudentLife = (data) => {
    return Axios({
        method: "GET",
        url: `${baseUrl}/student-life`
    })
}

export const requestAdmission = (data) => {
    return Axios({
        method: 'POST',
        url: `${baseUrl}/admissions`,
        data: data
    })
}

export const requestInformation = (data) => {
    return Axios({
        method: 'POST',
        url: `${baseUrl}/request-informations`,
        data: data
    })
}