import Axios from 'axios';
import React, { useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import './Career.scss';
import { ReactComponent as CompleteIcon } from '../../assets/complete.svg';
import { ReactComponent as ErrorIcon } from '../../assets/error.svg';

import {baseUrl} from '../../api/api';
import { useHistory } from 'react-router-dom';
import Loading from '../../Components/Loading/Loading';

export const Career = (props) => {
    const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const history = useHistory();
    const onSubmit = (value) => {
        console.log(value);
        setModal(true)
        setLoading(true)
        Axios({
            method: 'POST',
            url: `${baseUrl}/career-applications`,
            data: {...value, status: 'PENDING'}
        }).then(()=>{
            setLoading(false)
        }).
        catch(()=>{
            setLoading(false)
            setError("something went wrong")
        })
    }
    return (
        <Container>
            <h4 className="text-bold text-center">Want to join our team ?</h4>
            <Row className="justify-content-center">
                <Col lg={6}>
                    <div className="card-wrapper my-5 p-5">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label>Full Name</label>
                                <input
                                    required
                                    placeholder=""
                                    className="form-control"
                                    name="name"
                                    type="text"
                                    placeholder="Your good name"
                                    ref={register({ required: true })}
                                />
                                {errors.name && <p className="form-error">{errors.name.message ? errors.name.message : "Your Name is Required"}</p>}
                            </div>
                            <div className="form-group">
                                <label>Address</label>
                                <input
                                    required
                                    placeholder=""
                                    className="form-control"
                                    name="address"
                                    type="text"
                                    placeholder="Your Address"
                                    ref={register({ required: true })}
                                />
                                {errors.address && <p className="form-error">{errors.address.message ? errors.address.message : "Your Address is Required"}</p>}
                            </div>
                            <div className="form-group">
                                <label>Qualification</label>
                                <input
                                    required
                                    placeholder=""
                                    className="form-control"
                                    name="qualification"
                                    type="text"
                                    placeholder="Your Qualification"
                                    ref={register({ required: true })}
                                />
                                {errors.qualification && <p className="form-error">{errors.qualification.message ? errors.qualification.message : "Your Qualification is Required"}</p>}
                            </div>
                            <div className="form-group">
                                <label>Contact</label>
                                <input
                                    required
                                    placeholder=""
                                    className="form-control"
                                    name="contact"
                                    type="text"
                                    placeholder="Your Contact"
                                    ref={register({ required: true })}
                                />
                                {errors.contact && <p className="form-error">{errors.contact.message ? errors.contact.message : "Your Contact is Required"}</p>}
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    required
                                    placeholder=""
                                    className="form-control"
                                    name="email"
                                    type="text"
                                    placeholder="Your Email"
                                    ref={register({ required: true })}
                                />
                                {errors.email && <p className="form-error">{errors.email.message ? errors.email.message : "Your Email is Required"}</p>}
                            </div>
                            <div className="form-group">
                                <label>Experience Details</label>
                                <textarea
                                    placeholder=""
                                    className="form-control"
                                    name="experience"
                                    type="text"
                                    placeholder="Your Experience"
                                    ref={register({ required: true })}
                                />
                                {errors.experience && <p className="form-error">{errors.experience.message ? errors.experience.message : "Your Experience is Required"}</p>}
                            </div>
                            <div className="form-group">
                                <label>Anything else?</label>
                                <textarea
                                    placeholder=""
                                    className="form-control"
                                    name="messege"
                                    type="text"
                                    placeholder="Your Messege"
                                    ref={register({ required: true })}
                                />
                                {errors.messege && <p className="form-error">{errors.messege.message ? errors.messege.message : "Your Messege is Required"}</p>}
                            </div>
                            <button
                                type="submit"
                                className="custom-button mt-3 button-primary button-solid"
                            >Submit</button>
                        </form>
                    </div>
                </Col>
            </Row>


            <Modal
                show={modal}
                centered
                onHide={() => {
                    setModal(false);
                    history.push('/')
                }}
            >
                {!loading &&
                    <Modal.Header closeButton>

                    </Modal.Header>
                }
                <Modal.Body>
                    {loading ? (
                        <Loading className="py-5" text="We are recording your application" />
                    ) : (
                            <div className="py-5">
                                {!error ? (
                                    <div className="success-response">
                                        <CompleteIcon />

                                        <h5 className="mt-3 text-bold">Congratulations!!</h5>
                                        <p className="text-center px-4">We have received your application. One of our representatives will get back to your very soon</p>
                                        <h6 className="mt-4 text-bold">Thank you.</h6>
                                    </div>
                                ) : (
                                        <div className="success-response">
                                            <ErrorIcon fill="red" width="50px" />
                                            <h3 className="mt-3 text-bold" style={{ color: 'red' }}>Error!!</h3>
                                            <p className="text-center px-4">Something Went Wrong</p>
                                            <h6 className="mt-4 text-bold">Please Try Again Later.</h6>
                                        </div>
                                    )}

                            </div>
                        )}

                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default Career;