import React, { useState } from 'react';
import NavigationBar from '../Components/NavigationBar/NavigationBar';
import { useSelector } from 'react-redux';
import Footer from '../views/Footer/Footer';
import NavigationBarAlt from '../Components/NavigationBar/NavigationBarAlt';
import { isMobile } from 'react-device-detect';
import MobileNavigation from '../Components/NavigationBar/MobileNavigation';

const Layout = (props) => {
	const { menuItems } = props;
	const [ spaceTop, setSpaceTop ] = useState(0);
	const route = useSelector((state) => state.router);
	const pageChange = (e) => {
		setSpaceTop(e);
	};
	return (
		<React.Fragment>
			{isMobile ? (
				<MobileNavigation menu={menuItems} />
			) : (
				<React.Fragment>
					{route.location.pathname === '/' ? (
						<NavigationBar menu={menuItems} onChange={(e) => pageChange(e)} />
					) : (
						<NavigationBarAlt menu={menuItems} onChange={(e) => pageChange(e)} />
					)}
				</React.Fragment>
			)}
			<div className="main" style={{ paddingTop: route?.location?.pathname  === '/' ? 0 : (spaceTop - 10 )}}>
				{props.children}
			</div>
			<Footer />
		</React.Fragment>
	);
};

export default Layout;
