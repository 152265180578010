import React, { useState, useRef, useEffect } from 'react';
import './TeamItem.scss';
import Card from '../../Components/Card/Card';
import ImageLoader from 'react-imageloader';
import ScrollAnimation from 'react-animate-on-scroll';
import { getImageUrl } from '../../utils/ImageUrl';

const TeamItem = (props) => {
	const [ topDistance, setTopDistance ] = useState(100);
	const [ cardHeight, setCardHeight ] = useState(300);
	const teamRef = useRef();
	const detailsRef = useRef();
	useEffect(() => {
		// if(teamRef.current && detailsRef.current){
		//     setCardHeight(teamRef.current.clientHeight + detailsRef.current.clientHeight - topDistance );
		// }
	});
	return (
		<ScrollAnimation animateIn="swing-in-top-fwd" style={{height: '100%'}}>
			<Card style={{ padding: 0, height: '100%' }}>
				<div className="team-item-wrapper d-flex flex-column align-items-center">
					<div className="member-details text-center">
						<ImageLoader
							className="team-image"
							src={
								props.data.imageUrl ? (
									getImageUrl(props.data.imageUrl.url)
								) : (
									'http://placehold.jp/200x150.png'
								)
							}
							style={{
								width: '100%',
								borderTopLeftRadius: '10px',
								borderTopRightRadius: '10px',
								minHeight: 100
							}}
							alt="Team Member"
						/>
						<div style={{ padding: '20px 0px' }}>
							<h3>{props.data.fullName}</h3>
							<p style={{ fontSize: 14 }}>{props.data.designation}</p>
							<h6>{ props?.data?.education}</h6>
						</div>
					</div>
				</div>
			</Card>
		</ScrollAnimation>
	);
};

export default TeamItem;
