import React from "react"

export const themes = {
    light: {
      foreground: '#DDDDDD',
      background: '#242424',
      primaryColor: '#0071BC',
      secondaryColor: '#F6AB19',
      secondaryText: '#767676'
    },
    dark: {
      foreground: '#FFFFFF',
      background: '#242424',
      primaryColor: '#FF3C3C',
      secondaryColor: '#FEA259',
      foregroundSecondary: '#DDDDDD',
      borderColor: '#686767',
      primaryGradient: `linear-gradient(to top, #FF3C3C, #FEA259)`
    },
  };
  
  export const ThemeContext = React.createContext();
