import React from 'react';
import './SectionTitle.scss';
// import ScrollAnimation from 'react-animate-on-scroll';
const SectionTitle = (props) => {
    const { variant } = props;
    const className = variant === 'light' ? 'title-light' : 'title-default';
    return (
        // <ScrollAnimation animateIn='tracking-in-contract-bck'>
        <React.Fragment>
            <div className={`section-title-wrapper d-flex ${props.left ? 'justify-content-start' : 'justify-content-center'} ${props.className} ${className}`} style={props.style}>
                <div className="title-content">
                    <h2>{props.text}</h2>
                </div>
            </div>
            {props.description &&
                <p className="text-center d-flex justify-content-center mb-5">
                    <span style={{ width: "60%", display: 'block', fontSize: '20px' }}>
                        {props.description}
                    </span>
                </p>
            }

        </React.Fragment>
        // </ScrollAnimation>
    )
}

export default SectionTitle;