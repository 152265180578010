import React, { useEffect } from 'react';
import './Team.scss';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import TeamItem from '../../Components/TeamItem/TeamItem';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeams } from '../../redux/actions/Teams';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
const Team = (props) => {
	const dispatch = useDispatch();
	const Teams = useSelector((state) => state.Teams);

	useEffect(
		() => {
			dispatch(fetchTeams('status=true&_sort=order:asc'));
		},
		[ 0 ]
	);
	return (
		<Container className="mb-5">
			<SectionTitle
				text="Board of Directors"
				description="No matter what kind of team you’re in or what role you play, Brilliant Teams gives you the core ingredients to make you and your team a truly brilliant one. You’ll find out exactly what it takes to excel as a team player and leader, as well as common team ‘traps’ to avoid."
			/>

			{Teams.teams && (
				<Row>
					{Teams.teams &&
						Teams.teams.map((t, index) => {
							return (
								<Col lg={3} className="mb-4">
									<TeamItem data={t} />
								</Col>
							);
						})}
				</Row>
			)}
			<div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center py-5">
				{Teams.fetching && <Loading className="align-items-center" />}
				{!Teams.fetching && Teams.teams.length === 0 && <EmptyHolder text="Team informations not available" />}
			</div>
		</Container>
	);
};

export default Team;
