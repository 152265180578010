import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import { takeStudentLife } from '../../api/api';
import { fetchStudentLifeSuccess, fetchStudentLifeFailure } from '../actions/StudentLife';
import { GET_STUDENT_LIFE } from '../constants';

const getStudentLifeAsync = async () => await takeStudentLife();

function* getStudentLife({payload}) {

    try {
        const student = yield call(getStudentLifeAsync);
        if(student){
            yield put(fetchStudentLifeSuccess(student.data))
        }
        else{
            yield put(fetchStudentLifeFailure("Something went wrong"));
        }
    } catch (error) {
        yield put(fetchStudentLifeFailure(error));
    }
}

function* watchGetStudentLife(){
    yield takeEvery(GET_STUDENT_LIFE, getStudentLife);
}

export default function* studentLifeSaga(){
    yield all([
        fork(watchGetStudentLife)
    ])
}