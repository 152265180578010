import React, { useEffect } from 'react';
import './Event.scss';
import EventSection from '../Dashboard/EventSection/EventSection';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import Event from '../../Components/Event/Event';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents } from '../../redux/actions/Events';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
const Events = (props) => {
	const events = useSelector((state) => state.Events);
	const dispatch = useDispatch();
	useEffect(
		() => {
			dispatch(fetchEvents('Status=true&_sort=DateFrom:asc'));
		},
		[ 0 ]
	);
	return (
		<div className="mt-n5">
			{events.events &&
			events.events.length > 0 && (
				<React.Fragment>
					{/* <EventSection data={events.events.slice(0, 3)} fetching={events.fetching} /> */}
					<Container>
						<SectionTitle left text="All Events" />
						<Row className="align-items-stretch">
							{events &&
								events.events.length > 3 &&
								events.events.map((data, index) => {
									return (
										<Col lg={3}>
											<Event data={data} notitle />
										</Col>
									);
								})}
						</Row>
					</Container>
				</React.Fragment>
			)}

			<div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center py-5">
				{events.fetching && <Loading className="align-items-center" />}
				{!events.fetching &&
				events.events &&
				events.events.length === 0 && <EmptyHolder text="Events not found" />}
			</div>
		</div>
	);
};

export default Events;
