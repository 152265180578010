import React from 'react';
import { useSelector } from 'react-redux';
import PrincipalMessage from '../Dashboard/PrincipalMessage/PrincipalMessage';
export const PrincipalMessages = (props) => {
	const Organization = useSelector((state) => state.Organization);

	return (
		<div className="mb-3">
			<PrincipalMessage data={Organization} />
		</div>
	);
};

export default PrincipalMessages;
