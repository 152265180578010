import React from 'react';
import Loading from '../Loading/Loading';
import './Pageloading.scss';

export const Pageloading = (props) => {
	return (
		<div className="loading--wrapper-page">
			<Loading />
		</div>
	);
};
export default Pageloading;
