import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { baseUrl } from '../../api/api';
import './StudentSpeaks.scss';
import { getImageUrl } from '../../utils/ImageUrl';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';

export const StudentSpeaks = (props) => {
	const [ notice, setNotice ] = useState(false);

	useEffect(
		() => {
			Axios({
				method: 'GET',
				url: `${baseUrl}/student-voices`
			})
				.then((data) => {
					setNotice((data.data && data.data) || []);
				})
				.catch((err) => console.log(err));
		},
		[ 0 ]
	);
	return (
		<Container>
			<SectionTitle text="Students Speaks" />
			<Row className="justify-content-center">
				<Col lg={8}>
					<div className="notices-wrapper">
						{notice &&
							notice.map((n, i) => {
								return (
									<div className="notice__item">
										<Row className="notice-item d-flex align-items-stretch">
											<Col lg={2} className="px-0">
												<img
													style={{ width: '100%', maxHeight: '100%' }}
													src={
														n?.image && n?.image?.url ? (
															getImageUrl(n.image.url)
														) : (
															'https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
														)
													}
												/>
											</Col>
											<Col lg={10} className="content__element">
												<div className="ml-4 flex-fill py-3">
													
                                                    <div style={{marginBottom: '10px'}} dangerouslySetInnerHTML={{ __html: n.content && n.content }} />
                                                    <h5 className="text-bold">{ n?.name ? `-${n?.name}/${n?.designation}` : ''}</h5>
												</div>
											</Col>
											<hr />
										</Row>
									</div>
								);
							})}
					</div>
				</Col>
			</Row>
		</Container>
	);
};
