import React, { useContext } from 'react';
import eventCover from '../../assets/event.png';
import { ThemeContext } from '../../utils/ThemeContext';
import CustomButton from '../CustomButton/CustomButton';
import './Event.scss';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getImageUrl } from '../../utils/ImageUrl';
const Event = (props) => {
	const { variant, title, fetching, data } = props;
	const className = variant ? `event-${variant}` : 'event-default';
	const theme = useContext(ThemeContext);
	return (
		<React.Fragment>
			{fetching ? (
				<div className={`event-wrapper ${className}`}>
					<SkeletonTheme
						color={variant === 'primary' ? '#cccccc' : '#e0e0e0'}
						highlightColor={variant === 'primary' ? '#eeeeee' : '#dee2e6'}
					>
						{!props.notitle && (
							<h3 className="title">
								<Skeleton height={50} />
							</h3>
						)}
						<div className="event-container">
							<Skeleton height={variant === 'primary' ? 150 : 180} />

							<div className="event--details">
								<Skeleton height={30} />

								<Skeleton height={15} width="70%" />

								{variant !== 'primary' && <Skeleton count={2} />}

								{variant === 'primary' && <Skeleton height={50} width="50%" />}
							</div>
						</div>
					</SkeletonTheme>
				</div>
			) : (
				<div className={`event-wrapper ${className}`}>
					{!props.notitle && (
						<h4 className="title">
							{title ? variant === 'primary' ? 'Next Event' : 'Events & Activities' : ''}
						</h4>
					)}
					<div className="event-container">
						<div style={{ height: '200px', overflow: 'hidden' }}>
							<img
								src={(data && getImageUrl(data.imageUrl.url)) || 'http://placehold.jp/380x240.png'}
								alt="Event Title"
							/>
						</div>
						<div className="event--details">
							<h4>{data && data.title}</h4>
							<em>{data && data.dateFrom}</em>
							<p className="pt-3" style={{ color: theme.secondaryText }}>
								{data && data.description}
							</p>
							{variant === 'primary' && (
								<CustomButton variant="secondary" type="bordered" text="Learn More" />
							)}
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};

export default Event;
