import React from 'react';
import './StudentLife.scss';
import { Row, Col, Container } from 'react-bootstrap';
import { Parallax } from 'react-parallax';

import Image from '../../../assets/slife.jpg';
import './StudentLife.scss';
import CustomButton from '../../../Components/CustomButton/CustomButton';
import LifeImage from '../../../assets/team-member.jpg';
import { useHistory } from 'react-router-dom';
import { getImageUrl } from '../../../utils/ImageUrl';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
import Slider from 'react-slick';

const StudentLife = (props) => {
	const history = useHistory();
	const { data, fetching } = props;

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};
	return (
		// <Parallax
		//     bgImage={Image}
		//     blur={1}
		//     strength={200}
		// >
		<div className="life-overlay" style={{ minHeight: '300px' }}>
			<Container>
				{!fetching &&
				data && (
					<Row className="align-items-center">
						<Col lg={6} className="py-5">
							<h1 style={{ color: 'white', textTransform: 'uppercase', fontSize: '1.5rem',fontWeight: 'bold',marginBottom:  30 }}>{data.title}</h1>
							<div
								style={{ color: '#a6dbff' }}
								dangerouslySetInnerHTML={{ __html: data.shortDescription || '' }}
							/>
							<CustomButton
								onClick={() => {
									history.push('/students');
								}}
								text="Learn More"
								type="solid"
								style={{ color: 'white' }}
								variant="primary"
							/>
						</Col>
						<Col lg={6}>
							{/* <img
								src={
									data.featuredImage ? (
										getImageUrl(data.featuredImage.url)
									) : (
										'http://placehold.jp/1800x1400.png'
									)
								}
								alt="Life"
								style={{ width: '100%' }}
							/> */}
							<Slider {...settings} className="class--slider">
								{data?.studentLife &&
									data?.studentLife?.map((cs, index) => {
										return (
											<div>
												<img
													src={
														cs ? (
															getImageUrl(cs?.url)
														) : (
															'http://placehold.jp/1800x1400.png'
														)
													}
													alt="Life"
													style={{ width: '100%' }}
												/>
											</div>
										);
									})}
							</Slider>
						</Col>
					</Row>
				)}
				{fetching &&
				data.length === 0 && (
					<div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center">
						{fetching && <Loading className="align-items-center" />}
						{data.length === 0 && <EmptyHolder text="Banners not available" />}
					</div>
				)}
			</Container>
		</div>
		// </Parallax >
	);
};

export default StudentLife;
