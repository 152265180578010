import React from 'react';
import TeamItem from '../../../Components/TeamItem/TeamItem';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../../../Components/SectionTitle/SectionTitle';
import ShowMore from '../../../Components/ShowMore/ShowMore';
import { useHistory } from 'react-router-dom';
import Loading from '../../../Components/Loading/Loading';
import EmptyHolder from '../../../Components/EmptyHolder/EmptyHolder';
const teammembers = Array(4).fill(null);

const OurTeam = (props) => {
	let history = useHistory();
	const { fetching, data } = props;
	return (
		<Container>
			<SectionTitle text="Board of Directors" />

			<Row className="align-items-strength">
				{props.data &&
					props.data.map((data, index) => {
						return (
							<Col key={index} lg={3} className="mb-4">
								<TeamItem data={data} />
							</Col>
						);
					})}
			</Row>
			{props.data &&
			props.data.length > 4 && (
				<ShowMore
					onClick={() => {
						history.push('/team');
					}}
				/>
			)}
			<div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center py-5">
				{fetching && <Loading className="align-items-center" />}
				{!fetching && data.length === 0 && <EmptyHolder text="Banners not available" />}
			</div>
		</Container>
	);
};

export default OurTeam;
