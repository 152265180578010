import { GET_STUDENT_LIFE, GET_STUDENT_LIFE_SUCCESS, GET_STUDENT_LIFE_FAILURE } from "../constants"

export const fetchStudentLife = () => ({
    type: GET_STUDENT_LIFE
})

export const fetchStudentLifeSuccess = (student) => ({
    type: GET_STUDENT_LIFE_SUCCESS,
    payload: {student: student}
})

export const fetchStudentLifeFailure = (err) => ({
    type: GET_STUDENT_LIFE_FAILURE,
    payload: {err: err}
})