import { GET_COURSES, GET_COURSES_SUCCESS, GET_COURSES_FAILURE, GET_COURSE_DETAILS, GET_COURSE_DETAILS_SUCCESS, GET_COURSE_DETAILS_FAILURE } from "../constants";

export const fetchCourses =(params) =>({
    type: GET_COURSES,
    payload: {params: params}
})

export const fetchCoursesSuccess = (courses) =>({
    type: GET_COURSES_SUCCESS,
    payload: {courses: courses}
})

export const fetchCoursesFailure = (err) =>({
    type: GET_COURSES_FAILURE,
    payload: {err: err}
})

// course detais
export const fetchCourseDetails =(params) =>({
    type: GET_COURSE_DETAILS,
    payload: {params: params}
})

export const fetchCourseDetailsSuccess = (course) =>({
    type: GET_COURSE_DETAILS_SUCCESS,
    payload: {course: course}
})

export const fetchCourseDetailsFailure = (err) =>({
    type: GET_COURSE_DETAILS_FAILURE,
    payload: {err: err}
})