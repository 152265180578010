import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router';
import organizationInfo from './organizationInfo';
import Admission from './Admission';
import Banners from './Banners';
import Events from './Events';
import Facilities from './Facilities';
import Courses from './Courses';
import Teams from './Teams';
import Galleries from './Galleries';
import StudentLife from './StudentLife';
const reducers = (history) => combineReducers({
    router: connectRouter(history),
    Organization: organizationInfo,
    Admission: Admission,
    Banners: Banners,
    Events: Events,
    Facilities: Facilities,
    Course: Courses,
    Teams: Teams,
    Galleries: Galleries,
    StudentLife: StudentLife,
})

export default reducers;