import { Nav, Container, NavDropdown } from 'react-bootstrap';
import React from 'react';
import { primaryMenu } from '../../data/data';
import { history } from '../../redux/store';
export const NavMenus = (props) => {
    return (
        <Nav className={props?.className}>
            {primaryMenu?.map((m, index) => {
                return (
                    <React.Fragment>
                        {m?.children ? (
                            <NavDropdown className="dropdown--menu" title={ m?.name} id="basic-nav-dropdown">
                                {m?.children?.map((c) => {
                                    return (
                                        <NavDropdown.Item href={c?.path}>{ c?.name}</NavDropdown.Item>
                                    )
                                })}
                            </NavDropdown>
                        ) : (
                            <Nav.Item key={m.path}>
                                <Nav.Link
                                    onClick={() => {
                                        if (m.redirect) {
                                            window.open(m.path, '_blank');
                                        } else {
                                            history.push(m.path);
                                        }
                                        // console.log(history)
                                    }}
                                >
                                    {m.name}
                                </Nav.Link>
                            </Nav.Item>
                        )}
                    </React.Fragment>
                )
            })}

        </Nav>
    );
};

export default NavMenus;
