import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SectionTitle from '../../../Components/SectionTitle/SectionTitle';
import { getImageUrl } from '../../../utils/ImageUrl';
export const PrincipalMessage = (props) => {
	const { data } = props;
	console.log(data);
	return (
		<div style={{ background: '#0071bc' }}>
			{/* <SectionTitle text="All Courses" /> */}
            <Container>
                <Row className="align-items-center">
                    <Col lg={8}>
                        <div className="py-5 px-3">
                            <h3 className="mb-4" style={{color: 'white', textTransform: 'uppercase',fontWeight: 'bold'}}>Message from the Principal</h3>
                            <div style={{color: '#a6dbff', lineHeight: '28px',fontSize: '16px'}} dangerouslySetInnerHTML={{__html: data?.info?.principalMessage}}></div>
                        </div>

                    </Col>
                    <Col lg={4}>
                        <img style={{width: '100%' }} src={getImageUrl(data?.info?.principalImage?.url)}/>
                    </Col>
                    </Row>
            </Container>
		</div>
	);
};

export default PrincipalMessage;
