import React, { useEffect, useState } from 'react';
import './Course.scss';
import { Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import CourseCard from '../../Components/CourseCard/CourseCard';
import SectionTitle from '../../Components/SectionTitle/SectionTitle';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../Components/Loading/Loading';
import EmptyHolder from '../../Components/EmptyHolder/EmptyHolder';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCourses } from '../../redux/actions/Courses';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Course = (props) => {
	const history = useHistory();
	const Course = useSelector((state) => state.Course);

	const [query, setQuery] = useState('');

	const dispatch = useDispatch();
	const location = useLocation();
	const level = location?.pathname?.split('/')[1]

	const getQuery = (props) => {
		switch (level) {
		case 'plus-two':
				return 'PLUS_TWO'
			case 'bachelor':
				return 'BACHELOR'
			case 'master':
				return 'MASTER'
			default:
				return ''
	}	
	}
	
	useEffect(
		() => {
			if (getQuery(level)) {
				dispatch(fetchCourses(`status=true&level=${getQuery(level)}`));
			}
			else {
				dispatch(fetchCourses(`status=true`));
			}
		},
		[ level ]
	);
	return (
		<Container>
			<SectionTitle text="Our Courses" />
			<Row className="m-0 justify-content-start">
				{Course.courses &&
					Course.courses.map((cs, index) => {
						return (
							<Col lg={4} md={4} style={{ padding: '0px 20px', marginBottom: 30 }}>
								<ScrollAnimation animateOnce animateIn="scale-in-center">
									<CourseCard data={cs} onClick={() => history.push(`/courses/${cs.slug}`)} />
								</ScrollAnimation>
							</Col>
						);
					})}
			</Row>
			<div style={{ height: '100%' }} className="d-flex align-items-center justify-content-center py-5">
				{Course.fetching && <Loading className="align-items-center" />}
				{!Course.fetching &&
				Course.courses.length === 0 && <EmptyHolder text="No courses found Please visit later" />}
			</div>
		</Container>
	);
};

export default Course;
