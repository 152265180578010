import React, { useEffect, useState } from 'react';
import './App.scss';
import { Switch, Route } from 'react-router-dom';
import Home from '../views/Home/Home';
import About from '../views/About/About';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../redux/store';
import Layout from './Layout';
import { primaryMenu } from '../data/data';
import Admission from '../views/Admission/Admission';
import Course from '../views/Course/Course';
import Team from '../views/Team/Team';
import Contact from '../views/Contact/Contact';
import Event from '../views/Event/Event';
import { useSelector, useDispatch } from 'react-redux';
import CourseDetails from '../views/Course/Details/Details';
import { fetchOrganizationDetails } from '../redux/actions/organizationInfo';
import { fetchCourses } from '../redux/actions/Courses';
import StudentLife from '../views/StudentLife/StudentLife';
import Axios from 'axios';
import { baseUrl } from '../api/api';
import { Modal, Row } from 'react-bootstrap';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { getImageUrl } from '../utils/ImageUrl';
import Career from '../views/Career/Career';
import { Notice } from '../views/Notice/Notice';
import ReactGA from 'react-ga';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import EmptyHolder from '../Components/EmptyHolder/EmptyHolder';
import EmptyPage from '../Components/EmptyHolder/EmptyPage';
import PrincipalMessages from '../views/PrincipalMessages/PrincipalMessages';
import { StudentSpeaks } from '../views/StudentSpeaks/StudentSpeaks';

const App = (props) => {
	const route = useSelector((state) => state.router);
	const dispatch = useDispatch();
	const [ notice, setNotice ] = useState(false);

	ReactGA.initialize('UA-179929793-1');

	useEffect(
		() => {
			dispatch(fetchOrganizationDetails());
			// dispatch(fetchCourses('status=true&_sort=order:asc'));
			dispatch(fetchOrganizationDetails());
		},
		[ 0 ]
	);

	useEffect(
		() => {
			Axios({
				method: 'GET',
				url: `${baseUrl}/notices`
			})
				.then((data) => {
					setNotice(data.data && data.data[0]);
				})
				.catch((err) => console.log(err));
		},
		[ 0 ]
	);

	useEffect(
		() => {
			window.scrollTo(0, 0);
			ReactGA.pageview(window.location.pathname + window.location.search);
		},
		[ route ]
	);
	return (
		<Layout menuItems={primaryMenu}>
			<Modal
				show={notice}
				onHide={() => {
					setNotice(false);
				}}
				size="lg"
			>
				<Modal.Header closeButton>
					<h5 className="text-bold">{notice && notice.title && notice.title}</h5>
				</Modal.Header>
				<Modal.Body>
					{/* <Row className="justify-content-end">
            <div>
              <span>Close</span>

              <CloseIcon className="ml-2" width="20px" />
            </div>
          </Row> */}
					<Row>
						<img
							style={{ width: '100%' }}
							src={notice && notice.imageUrl && getImageUrl(notice.imageUrl.url)}
						/>
						<div
							className="p-5"
							dangerouslySetInnerHTML={{ __html: notice && notice.content && notice.content }}
						/>
					</Row>
				</Modal.Body>
			</Modal>
			<ConnectedRouter history={history}>
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/about-us" exact component={About} />
					<Route path="/introduction" exact component={About} />
					<Route path="/mission-vision" exact component={About} />
					<Route path="/principal-message" exact component={PrincipalMessages} />
					<Route path="/admission" exact component={Admission} />
					<Route path="/courses" exact component={Course} />
					<Route path="/bachelor" exact component={Course} />
					<Route path="/plus-two" exact component={Course} />
					<Route path="/master" exact component={Course} />
					<Route path="/school" exact component={Course} />
					<Route path="/notices" exact component={Notice} />
					<Route path="/student-speaks" exact component={StudentSpeaks} />
					<Route path="/bod" exact component={Team} />
					<Route path="/contact-us" exact component={Contact} />
					<Route path="/events" exact component={Event} />
					<Route path="/career" exact component={Career} />
					<Route path="/students" exact component={StudentLife} />
					<Route path="/courses/:slug" exact component={CourseDetails} />
					<Route component={EmptyPage} />
				</Switch>
			</ConnectedRouter>
		</Layout>
	);
};

export default App;
